import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import FourOFour from '../components/404'

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <FourOFour />
    </Layout>
)

export default NotFoundPage
